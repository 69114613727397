function Content() {
  return (
    <div className="content-container">
      <a href="" className="card2" data-toggle="modal" data-target="#reitlionclaim">
        <div className="card2">
          <div className="face face1">
            <div className="content">
              <span className="stars"></span>
              <h2 className="java">ReitLion</h2>
              <p className="java">You can claim your JeroCoins by entering your token id (NFT id)</p>
            </div>
          </div>
          <div className="face face2">
            <h2>ReitLion</h2>
          </div>
        </div>
      </a>
      <div className="card2">
        <div className="face face1">
          <div className="content">
            <span className="stars"></span>
            <h2 className="python">ReitAngel</h2>
            <p className="python">Very soon...</p>
          </div>
        </div>
        <div className="face face2">
          <h2>ReitAngel</h2>
        </div>
      </div>

      <div className="card2">
        <div className="face face1">
          <div className="content">
            <span className="stars"></span>
            <h2 className="cSharp">ReitLioness</h2>
            <p className="cSharp">Very soon...</p>
          </div>
        </div>
        <div className="face face2">
          <h2>ReitLioness</h2>
        </div>
      </div>
    </div>
  );
}

export default Content;
