/**
 * Author : Emre
 * Set account address and eth balance then return network id
 *
 * @param {function} setAccount function for set account state
 * @param {function} setEthBalance function for set ethBalance state
 * @return {any} return network id
 */

 async function loadBCData(setAccount){
    const web3 =window.web3;
    const accounts=await web3.eth.getAccounts()
    //Get ETH balance
    if(accounts[0]!==undefined){
        setAccount(accounts[0])
    }else{
        alert("Connect your Metamask Wallet")
    }
    //fetch networkId
    const networkId = await web3.eth.net.getId();
    return networkId;
}

export default loadBCData;