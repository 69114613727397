import Web3 from 'web3';
/**
 * Author: Emre
 * Loads Web3
*/
async function loadWeb3(){
    if(window.ethereum){
        window.web3 = new Web3(window.ethereum)
        await window.ethereum.enable()
    }
    // Legacy dapp browsers
    else if(window.web3){
        window.web3=new Web3(window.web3.currentProvider)
    }else{
        window.alert('Non-ethereum browser detected. Setup metamask')
    }
}

export default loadWeb3;