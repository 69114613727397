import React, { useEffect, useState } from 'react';
import Content from './components/Content';
import loadWeb3 from './helpers/loadWeb3';
import loadBCData from './helpers/loadBCData';
import JeroContract from './contracts/JeroCoin.json';
function App() {
  const [account,setAccount] = useState("0x0");
  const [jeroIns,setJeroIns] = useState();
  const [tokenId,setTokenId] = useState(0);
  
  //Wallet onchange listener
  if(window.ethereum){
    window.ethereum.on('accountsChanged',  (accounts)=> {
      // Time to reload interface with accounts[0]!
      setAccount(accounts[0])
    })
  }
  useEffect(() => {
    async function load(){
      await loadWeb3();
      const netId=await loadBCData(setAccount);
      //check kovan network
      if(netId!==137){
        alert("Please change yor network to Polygon(MATIC)")
      }else{        
        const jeroContractInstance = new window.web3.eth.Contract(JeroContract.abi,"0x91642fB562F0736757E47f1853026A32e601a2c5");
        setJeroIns(jeroContractInstance);
      }
    }
    load();
  }, [account]);
  const claimJero = async()=>{
    if(account == "0x0"){
      alert("Please connect your metamask");
      return;
    }
    if(tokenId<1){
      alert("Please enter valid token id");
      return;
    }
    console.log("Claim ",account,tokenId);
    await jeroIns.methods.claimJeroCoin(tokenId,"0").send({from:account});
  }
  const tIdOnChange = (e)=>{
    setTokenId(e.target.value);
  }
  return (
    <div>
      <div id="intro" className="intro-content">
        <div className="overlay overlay-advanced overlay-intro">
          <div className="overlay-inner bg-cover" style={{ backgroundImage: "url('assets/images/photos/photo-1.jpg')" }}></div>
          <div className="overlay-inner overlay-video">
            <video autoPlay muted loop>
              <source src="assets/video/video.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="overlay-inner bg-dark opacity-70"></div>
        </div>

        <div className="intro-content-inner inner-left min-vh-100 d-flex align-items-center py-9 py-lg-12">
          <div className="container text-center text-white">
            <a href="https://metapera.io/"target="_blank">
            <img src='assets/images/logo.png'></img>
            </a>
            <h1 className="display-4 mb-4">JeroCoin Claim!</h1>
            <p className="lead mb-7">Please select REIT NFT Project</p>
            <Content/>
          </div>
        </div>

        <ul className="list-inline position-absolute bottom-0 left-0 mb-4 mb-lg-5 ml-4 ml-lg-5">

          <li className="list-inline-item mx-0">
            <a className="btn btn-sm btn-icon btn-soft-white" href="https://discord.com/invite/5Zv64MubZZ" tabIndex="0" target="_blank">
              <i className="fab fa-discord btn-icon-inner"></i>
            </a>
          </li>

          <li className="list-inline-item mx-0">
            <a className="btn btn-sm btn-icon btn-soft-white" href="https://twitter.com/metapera" tabIndex="0" target="_blank">
              <i className="fab fa-twitter btn-icon-inner"></i>
            </a>
          </li>

          <li className="list-inline-item mx-0">
            <a className="btn btn-sm btn-icon btn-soft-white" href="https://github.com/metapera" tabIndex="0" target="_blank">
              <i className="fab fa-github btn-icon-inner"></i>
            </a>
          </li>

          <li className="list-inline-item mx-0">
            <a className="btn btn-sm btn-icon btn-soft-white" href="https://medium.com/@metapera" tabIndex="0" target="_blank">
              <i className="fab fa-medium btn-icon-inner"></i>
            </a>
          </li>

        </ul>

      </div>
      <div className="modal fade" id="reitlionclaim" tabIndex="-1" role="dialog" aria-labelledby="subscribeNewsletter" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content hover-parent">

            <button type="button" className="btn btn-sm btn-icon btn-white position-absolute top-0 right-0 mt-n3 mr-n3 zindex-1" data-dismiss="modal" aria-hidden="true">
              <i className="fas fa-times btn-icon-inner"></i>
            </button>

            <div className="overlay overlay-advanced">
              <div className="overlay-inner bg-image-holder bg-cover">
                <img src="assets/images/photos/photo-3.jpg" alt=""></img>
              </div>
              <div className="overlay-inner bg-primary opacity-70 hover-opacity-80"></div>
              <div className="overlay-inner bg-gradient-v-transparent-dark opacity-90 hover-opacity-80"></div>
            </div>

            <div className="modal-body py-5 py-lg-8 px-5 px-lg-8 text-white text-center">

              <h2 id="subscribeNewsletter" className="mb-4">ReitLion Claim</h2>
              <p className="mb-10">Which ReitLion NFT you want to claim for ?</p>
              <p className="mb-10">We highly recommend setting transaction fee to 'high' because of high volatility of gas prices on Polygon Network.</p>
              <div className="subscribe-form">
                <div>
                  <div className="form-process"></div>
                  <div className="form-group">
                    <input type="number" placeholder="Enter Your REIT Lion Token Id" className="form-control required" onChange={tIdOnChange}></input>
                  </div>
                  <input type="text"  className="form-control d-none" ></input>
                  <button className="btn btn-soft-white btn-block"onClick={claimJero}>Claim</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
